import {
  FC,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from 'react'
import { startsWith, sumBy } from 'lodash'
import { useRouter } from 'next/router'
import { useQuery, useQueryClient } from 'react-query'
import { useToggle } from 'react-use'

import { showToast, getCurrentHFCart } from '~lib'
import { getShopifySdk } from '~lib/shopify/client'
import {
  PaymentProvidersQuery,
  CartFragmentFragment,
  CartLineInput,
  CartLineUpdateInput,
} from '~lib/shopify/sdk'

const LOCAL_STORAGE_CART_ID_KEY = 'cartId'
const SUBSCRIPTION_CHECKOUT = 'subscription-checkout'

export const alertMessage = 'Something went wrong'

export interface ShopifyContextType {
  // Checkout
  cart: CartFragmentFragment
  updatingCart: boolean
  addItemToCart: (
    lineItems: CartLineInput[],
    lineItemType: string,
  ) => Promise<void>
  adjustLineItemQuantity: (lineItem: CartLineUpdateInput) => void
  removeLineItem: (id: string) => void
  clearCart: () => void
  showCartOverlay: boolean
  toggleCartOverlay: (nextValue?: boolean) => void
  cartIsDisabled: boolean
  cartIsLoading: boolean
  paymentProviders: PaymentProvidersQuery['shop']['paymentSettings']
  portion: string
  setPortion: (portion: string) => void
  pendingAddToCart: string
  setPendingAddToCart: (id: string | null) => void
  toast: (content: string) => void
  toastContent: string | boolean
  quantity: number
  updateCartAttributes: any
}

export const ShopifyContext = createContext<ShopifyContextType>(undefined)

export const ShopifyProvider: FC = ({ children }) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  // const intl = useIntl();

  const [cartId, setCartIdState] = useState('')
  const [cartIdInitialized, setCartIdInitialized] = useState(false)

  const [updatingCart, setUpdatingCart] = useState(false)
  const [paymentProviders, setProviders] = useState(null)
  const [itemsToAdd, setItemsToAdd] = useState('')
  const [showCartOverlay, toggleCartOverlay] = useToggle(false)

  const [portion, setPortion] = useState('selectable')
  const [pendingAddToCart, setPendingAddToCart] = useState(null)

  // TODO replace with react-toastify
  const [toastContent, setToastContent] = useState(false)

  const toast = content => {
    setToastContent(content)
    setTimeout(() => setToastContent(false), 2000)
  }

  const shopifySdk = useMemo(
    () => getShopifySdk(router?.locale),
    [router?.locale],
  )

  const cartIsDisabled = !cartId

  const { data: cart, isLoading: cartIsLoading } = useQuery(
    ['cart', cartId],
    async () =>
      shopifySdk.cart({ id: cartId }).then(({ cart }) => {
        return cart as CartFragmentFragment
      }),
    {
      enabled: !cartIsDisabled,
    },
  )

  const setSubscriptionCheckoutId = (id: string) => {
    localStorage.setItem(SUBSCRIPTION_CHECKOUT, id)
  }

  const setCartId = (id: string) => {
    localStorage.setItem(LOCAL_STORAGE_CART_ID_KEY, id)
    setCartIdState(id)
  }

  /**
   * If there is a checkoutId in localStorage, set it
   * If checkout is completed or invalid, unset the checkoutId
   */

  useEffect(() => {
    const cartId = localStorage.getItem(LOCAL_STORAGE_CART_ID_KEY)
    if (cartId) {
      setCartIdState(cartId)
      shopifySdk
        .paymentProviders()
        .then(res => setProviders(res.shop.paymentSettings))
        .catch(err => console.log(err))

      setCartIdInitialized(true)
    } else {
      shopifySdk
        .cartCreate({
          input: { lines: [] },
        })
        .then(res => {
          const { cartCreate } = res
          setCartId(cartCreate.cart.id)

          shopifySdk
            .paymentProviders()
            .then(res => setProviders(res.shop.paymentSettings))
            .catch(err => console.log(err))

          setCartIdInitialized(true)
        })
    }
  }, [])

  /**
   * If checkout is completed or invalid, unset the checkoutId
   */
  useEffect(() => {
    if (cart?.lines?.edges?.length == 0) {
      setSubscriptionCheckoutId('')
    }

    // if there's a checkout, set portion according to items in cart
    if (!portion && cart?.lines?.edges?.length) {
      setPortion(
        cart?.lines?.edges?.[0].node?.merchandise?.product?.tags
          .find(tag => startsWith(tag, 'portion'))
          ?.split('_')?.[1],
      )
    }

    const subscriptionCheckoutId = localStorage.getItem('fh-subscription-id')

    if (subscriptionCheckoutId && cartIdInitialized) {
      getCurrentHFCart(subscriptionCheckoutId).then(res => {
        if (res && res?.subscriptions && res?.subscriptions?.nodes) {
          const subscriptionStatus = res.subscriptions.nodes.find(
            el => el.id == subscriptionCheckoutId,
          )

          if (subscriptionStatus && subscriptionStatus?.signupCompletedAt) {
            localStorage.removeItem('fh-subscription-id')
            clearCart()
          }
        }
      })
    }
  }, [cart])

  const quantity = useMemo(() => {
    const toReturn = sumBy(cart?.lines?.edges, item => item?.node?.quantity)
    return toReturn
  }, [cart])

  // *************************
  // Checkout ****************
  // *************************

  const trackAddToCart = (lineItems: CartLineInput[], cartData) => {
    lineItems.forEach(({ merchandiseId, quantity }) => {
      const item = cartData?.lines?.edges?.find(
        ({ node }) => merchandiseId === node.variant.id,
      )

      if (item?.node?.variant) {
        const dimension1 = item.node.variant.selectedOptions?.find(
          ({ name }) => name === 'Size',
        )?.value

        // track({
        //   event: 'addToCart',
        //   ecommerce: {
        //     value: `${item?.node?.variant?.priceV2?.amount}`,
        //     currencyCode: item?.node?.variant?.priceV2?.currencyCode,
        //     add: {
        //       products: [
        //         {
        //           id: item.node.id,
        //           variant: item.node.variant.sku,
        //           quantity: `${quantity}`,
        //           name: item?.node?.title,
        //           price: `${item?.node?.variant?.priceV2?.amount}`,
        //           brand: 'Plant B',
        //           ...(dimension1 ? { dimension1 } : {}),
        //           category: item.node.variant.product?.tags
        //             ?.find((tag) => tag?.startsWith('collection_'))
        //             ?.replace('collection_', ''),
        //         },
        //       ],
        //     },
        //   },
        // });
      }
    })
  }

  const addItemToCart = async (lines: CartLineInput[], lineItemType) => {
    try {
      setUpdatingCart(true)

      if (!cartId) {
        const { cartCreate } = await shopifySdk.cartCreate({
          input: { lines: lineItemType === 'is_box' ? lines : [] },
        })

        setCartId(cartCreate.cart.id)

        if (lineItemType !== 'is_box') {
          const { cartLinesAdd } = await shopifySdk.cartLinesAdd({
            cartId,
            lines: lines,
          })

          queryClient.setQueryData(['cart', cartId], cartLinesAdd.cart)
          trackAddToCart(lines, cartLinesAdd.cart)
        } else {
          queryClient.setQueryData(['cart', cartId], cartCreate.cart)
          trackAddToCart(lines, cartCreate.cart)
        }
      } else {
        if (
          cart?.lines?.edges?.some(
            ({ node }) =>
              !node?.merchandise?.product?.tags?.includes(lineItemType),
          )
        ) {
          await clearCart()
        }

        if (lineItemType == 'is_box') {
          await clearCart()
        }

        const { cartLinesAdd } = await shopifySdk.cartLinesAdd({
          cartId,
          lines: lines,
        })

        queryClient.setQueryData(['cart', cartId], cartLinesAdd.cart)
      }
    } catch (e) {
      console.warn(e)
      showToast(e.message)
      setCartIdState('')
      setSubscriptionCheckoutId('')
    } finally {
      setUpdatingCart(false)
    }
  }

  const adjustLineItemQuantity = async (lineItem: CartLineUpdateInput) => {
    try {
      setUpdatingCart(true)

      if (!lineItem.quantity) {
        removeLineItem(lineItem.id)
        return
      }
      const { cartLinesUpdate } = await shopifySdk.cartLinesUpdate({
        cartId,
        lines: [
          {
            id: lineItem?.id,
            quantity: lineItem?.quantity,
          },
        ],
      })

      queryClient.setQueryData(['cart', cartId], cartLinesUpdate.cart)

      const cartLineItem = cartLinesUpdate?.cart?.lines?.edges?.find(
        ({ node }) => node?.id === lineItem?.id,
      )

      if (cartLineItem) {
        trackAddToCart(
          [
            {
              merchandiseId: cartLineItem?.node?.merchandise?.id,
              quantity: cartLineItem?.node?.quantity,
            },
          ],
          cartLinesUpdate.cart,
        )
      }
    } catch (e) {
    } finally {
      setUpdatingCart(false)
    }
  }

  const removeLineItem = async (id: string) => {
    const { cartLinesRemove } = await shopifySdk.cartLinesRemove({
      cartId,
      lineIds: [id],
    })

    if (!!itemsToAdd) {
      let parsedItems = JSON.parse(itemsToAdd)
      parsedItems = parsedItems.filter(item => item.variantId !== id)
      setItemsToAdd(JSON.stringify(parsedItems))
    }

    queryClient.setQueryData(['cart', cartId], cartLinesRemove.cart)
  }

  const clearCart = async () => {
    const { cartLinesRemove } = await shopifySdk.cartLinesRemove({
      cartId,
      lineIds: cart?.lines.edges.map(el => el.node.id),
    })
    setItemsToAdd('')
    queryClient.setQueryData(['cart', cartId], cartLinesRemove.cart)
  }

  const updateCartAttributes = async input => {
    const { cartAttributesUpdate } = await shopifySdk.cartAttributesUpdate({
      cartId,
      attributes: input,
    })
    queryClient.setQueryData(
      ['subscriptionCart', cartId],
      cartAttributesUpdate.cart,
    )
  }

  return (
    <ShopifyContext.Provider
      value={{
        // Checkout
        toast,
        toastContent,
        addItemToCart,
        removeLineItem,
        clearCart,
        adjustLineItemQuantity,
        updatingCart,
        cart,
        showCartOverlay,
        toggleCartOverlay,
        cartIsLoading,
        cartIsDisabled: cartIdInitialized && cartIsLoading,
        paymentProviders,
        portion,
        setPortion,
        pendingAddToCart,
        setPendingAddToCart,
        quantity,
        updateCartAttributes,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  )
}

export const useShopify = () => {
  const ctx = useContext(ShopifyContext)

  if (!ctx) {
    throw new Error(
      'Shopify context must be used within a shopify context provider',
    )
  }

  return ctx
}
